import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import bgRollet from './images/rollet-1.jpeg'; 

const RollerShuttersInfo = () => {
  return (
    <Parallax id="roller-shutters-info" className=" mx-lg-5 mx-xs-0 py-lg-5 py-xs-0 bg-light " bgImage={`${bgRollet}`} blur={3} strength={-500}>
      <Container className='p-5 bg-dark opacity-50 text-white border shadow'>
        <h2 className="text-center mb-5 fw-lighter display-4 fw-bold">Немного о роллетах...</h2>
        <Row>
          <Col lg={7} xs={12} className='mb-3'>
            <h3>Что такое роллеты?</h3>
            <p>Роллеты представляют собой горизонтальные секции из металла, пластика или дерева, которые устанавливаются на окна и двери. Они могут быть автоматическими или ручными, и используются для защиты от солнца, шума и нежелательных посягательств.</p>
          </Col>
          <Col lg={7}>
          </Col>
          <Col lg={5}>
            <h3>Для чего нужны роллеты?</h3>
            <p>Роллеты предоставляют ряд преимуществ, включая защиту от внешних воздействий, повышение безопасности и улучшение теплоизоляции помещения. Они также способствуют сохранению конфиденциальности и комфортному пребыванию в помещении.</p>
          </Col>
          <Col lg={6}>
            <h3>Применение роллет</h3>
            <p>Роллеты широко используются как в жилых, так и в коммерческих помещениях. Они могут быть установлены на окна, двери, балконы, витрины и другие открытые пространства. Разнообразие дизайнов и материалов позволяет выбрать подходящий вариант для любого интерьера.</p>
          </Col>
          <Col lg={10}>
            <h3>Возможности роллет</h3>
            <ul>
              <li>Автоматическое и ручное управление</li>
              <li>Регулировка уровня освещенности и вентиляции</li>
              <li>Защита от взлома и нежелательных посягательств</li>
              <li>Улучшение теплоизоляции и энергоэффективности</li>
              <li>Совместимость с дополнительными системами безопасности</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Parallax>
  );
}

export default RollerShuttersInfo;
