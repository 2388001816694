import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import TelegramForm from './TelegramForm';

import "./styles/contactBlock.css";

const ContactBlock = () => {
  return (
    <section id="contact" className="contact__container py-5 ">
      <Container className='my-5 py-5'>
        <Row className="justify-content-center align-items-center">
          <Col lg={6} xs={12} className="text-center">
            <h2 className="mb-5">Наши контакты</h2>
            <p className="lead">Звоните, посчитаем, установим!</p>
            <Button variant="primary" size="lg" href="tel:+380123456789" className=" mb-2 w-50">Позвонить на телефон </Button>
            <br/>
            <Button variant="success" size="lg" href="https://t.me/yourtelegram" target="_blank" className='w-50'>Написать в Telegram</Button>
          </Col>
          <Col lg={5} xs={12}>
          <h2>Мы свяжемся с вами!</h2>
          <p>Заполните форму, чтобы заказать обратный звонок.</p>
          <TelegramForm/>

          </Col>
        </Row>
      </Container>

    </section>

  );
}

export default ContactBlock;
