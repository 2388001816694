import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import config from './config';
import './styles/UniqueOfferBlock.css';

const UniqueOfferBlock = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const messageText = `Имя: ${formData.name}\nНомер телефона: ${formData.phoneNumber} +10% скидка`;
    
    // Отправка сообщения во все указанные чаты
    for (const chatId of config.telegramChatIds) {
      try {
        const response = await axios.post(`https://api.telegram.org/bot${config.telegramBotToken}/sendMessage`, {
          chat_id: chatId,
          text: messageText
        });
        console.log(`Сообщение успешно отправлено в чат ${chatId}`);
      } catch (error) {
        console.error(`Ошибка при отправке сообщения в чат ${chatId}:`, error);
        alert(`Ошибка при отправке сообщения в чат ${chatId}.`);
      }
    }

    alert('Ваше сообщение успешно отправлено во все указанные чаты!');
  };

  return (
    <section id="unique-offer" className="py-5 bg-light">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} xs={12} className="text-center">
            <h2 className="mb-5 fw-lighter">Почему стоит выбрать нас?</h2>
            <p className="fw-light">Мы - лидеры на рынке установки роллет в Одессе и области. Наша команда специалистов гарантирует высокое качество работ и индивидуальный подход к каждому клиенту.</p>
            <p className='fw-bold'>При заказе установки роллет сейчас, вы получаете специальную скидку 10%. Заполните форму!</p>
          </Col>
          <Col lg={12} className="mt-4">
            <Row>
              <Col lg={12} xs={12} className="mb-3 mb-lg-0">
                <Form onSubmit={handleSubmit} className="d-md-flex justify-content-center align-items-center">
                  <Form.Group controlId="formName" className="my-2 col-12 col-md-4">
                    <Form.Control type="text" placeholder="Введите ваше имя" name="name" value={formData.name} onChange={handleInputChange} required />
                  </Form.Group>
                  <Form.Group controlId="formPhoneNumber" className=" col-12 col-md-4">
                    <Form.Control type="tel" placeholder="Введите ваш номер телефона" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} required />
                  </Form.Group>
                  <Button variant="outline-dark" type="submit" className='col-12 col-md-4 mobile-margin-bottom'>
                    Получить скидку
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default UniqueOfferBlock;
