import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import './styles/pricesBlock.css';

const PricesBlock = () => {
  return (
    <section id="prices" className="py-5">
      <Container>
        <h2 className="text-center mb-4">Цены</h2>
        <Row className="justify-content-center">
          <Col lg={8}>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Услуга</th>
                  <th>Цена (от)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Установка роллет</td>
                  <td>от 1485 грн</td>
                </tr>
                <tr>
                  <td>Ремонт роллет</td>
                  <td>от 900 грн</td>
                </tr>
                <tr>
                  <td>Техническое обслуживание роллет</td>
                  <td>от 600 грн/год</td>
                </tr>
                <tr>
                  <td>Аварийные вызовы</td>
                  <td>от 1200 грн</td>
                </tr>
                <tr>
                  <td>Изготовление роллет по индивидуальным размерам</td>
                  <td>по запросу</td>
                </tr>
                <tr>
                  <td>Консультации и проектирование роллет</td>
                  <td>от 800 грн</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PricesBlock;
