import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ProblemsBlock = () => {
  return (
    <section id="problems" className="py-5">
      <Container>
        <div className='circle__element'>
           
        </div>
        <h2 className="text-center mb-5 fw-lighter">Что беспокоит наших клиентов?</h2>
        <Row>
          <Col lg={6} className="mb-4">
            <h3 className='fw-bold'>Недостаточная безопасность</h3>
            <p className='fw-normal'>Многие клиенты сталкиваются с проблемой недостаточной безопасности своих домов из-за устаревших систем.</p>
            <p className='fw-bold'>Решение: Наши роллеты предоставляют высокий уровень защиты, обеспечивая ваш дом надежным барьером от нежелательных посягательств.</p>
          </Col>
          <Col lg={6} className="mb-4">
            <h3 className='fw-bold'>Низкая энергоэффективность</h3>
            <p className='fw-normal'>Старые роллеты могут быть непрозрачными и неэффективными с точки зрения теплоизоляции.</p>
            <p className='fw-bold'>Решение: Наши современные роллеты обеспечивают отличную теплоизоляцию, что помогает сэкономить на энергозатратах в течение года.</p>
          </Col>
          {/* Добавьте остальные примеры проблем и их решений здесь */}
        </Row>
      </Container>
    </section>
  );
}

export default ProblemsBlock;
