import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import Portfolio1 from './images/pexels-jan-van-der-wolf-11680885-14585335.jpg';  // Импорт изображения для первого слайда
import Portfolio2 from './images/pexels-heyho-7614610.jpg';  // Импорт изображения для второго слайда
import './styles/portfolioBlock.css';


const PortfolioBlock = () => {
  return (
    <section id="portfolio" className="py-5 bg-light">
      <Container>
        <h2 className="text-center mb-4">Портфолио</h2>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={Portfolio1}  // Использование импортированного изображения
              alt="Пример проекта 1"
            />
            <Carousel.Caption>
              <h3>Пример проекта 1</h3>
              <p>Описание проекта или клиента. Можете добавить дополнительные детали здесь.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={Portfolio2}  // Использование импортированного изображения
              alt="Пример проекта 2"
            />
            <Carousel.Caption>
              <h3>Пример проекта 2</h3>
              <p>Описание проекта или клиента. Можете добавить дополнительные детали здесь.</p>
            </Carousel.Caption>
          </Carousel.Item>
          {/* Добавьте дополнительные слайды при необходимости */}
        </Carousel>
      </Container>
    </section>
  );
}

export default PortfolioBlock;
