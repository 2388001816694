import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import workerImage from './images/worker-2.jpeg';

const TeamBlock = () => {
  return (
    <section id="team" className="py-5 my-0 bg-light fw">
      <Container>
      <div className='circle__element'>
           
           </div>
        <h2 className="text-center mb-5 fw-lighter">Наша команда</h2>
        <Row className="justify-content-center">
          <Col lg={6} className='bg-white p-lg-5 p-xs-0 rounded mb-lg-0 mb-xs-5'>
            <p className="lead text-left">Мы - команда опытных специалистов, специализирующихся на установке роллет. Наш опыт составляет более 10 лет, и мы гордимся своей репутацией профессионалов в отрасли.</p>
            <p className='fw-bold text-left'>Мы понимаем, что безопасность вашего дома - это важно, и поэтому мы делаем все возможное, чтобы предоставить вам надежные и современные решения. Работая в Одессе и области, мы обеспечиваем оперативное выполнение заказов и точное соблюдение сроков.</p>
            <p className='fw-bold text-left'>Вас ждет команда, готовая к быстрым просчетам и замерам, чтобы предложить вам оптимальные решения. Мы заботимся о каждом клиенте и гарантируем, что работа будет выполнена качественно и в срок.</p>
            <p className='fw-bold text-left'>Выбирая нас, вы можете быть уверены, что ваши роллеты будут установлены профессионалами, которые знают свое дело. Мы ценим доверие наших клиентов и стремимся к их полному удовлетворению.</p>
          </Col>
          <Col lg={6} xs={12} className='p-lg-5 p-xs-0 mx-auto'>
            <Image src={`${workerImage}`} rounded='true' fluid='true' className='mx-auto d-block'></Image>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TeamBlock;
