import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import config from './config';  // Импорт конфигурации
import "./styles/headerBlock.css";
import { Parallax } from 'react-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import backgroundImg from './images/bg.jpg';

const HeaderBlock = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const messageText = `Заказ консультации\n\nИмя: ${formData.name}\nНомер телефона: ${formData.phoneNumber}`;
    
    // Отправка сообщения во все указанные чаты
    for (const chatId of config.telegramChatIds) {
      try {
        const response = await axios.post(`https://api.telegram.org/bot${config.telegramBotToken}/sendMessage`, {
          chat_id: chatId,
          text: messageText
        });
        console.log(`Сообщение успешно отправлено в чат ${chatId}`);
      } catch (error) {
        console.error(`Ошибка при отправке сообщения в чат ${chatId}:`, error);
        alert(`Ошибка при отправке сообщения в чат ${chatId}.`);
      }
    }

    alert('Запрос на консультацию успешно отправлен во все указанные чаты!');
  };

  return (
    <Parallax id="HeaderBlock" className="mx-lg-5 mx-xs-0 my-4 mt-5 pt-5" bgImage={`${backgroundImg}`} blur={2} strength={-300} bgStyle={`{'background-position': "left" }`}>
      <Container className='bg-dark p-5 my-5 rounded-3 opacity-75 border border-primary header__container' >
        <Row className="align-items-center">
          <Col lg={6} xs={12}>
            <h1 className="text-white mb-2 fw-light mt-2">Лучшие роллеты в Одессе!</h1>
            <p className="text-white mb-5 fw-bold col-12 col-lg-10">
              Мы специализируемся на установке высококачественных роллет. 
              Обеспечим ваш дом надежной защитой и комфортом.
              Оставьте ваши контактные данные, и мы Вам перезвоним!</p>
            <Form className='col-12 col-lg-10 mb-5' onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control type="text" placeholder="Ваше имя" name="name" value={formData.name} onChange={handleInputChange} className='fw-bold' required />
              </Form.Group>
              <Form.Group controlId="formPhone" className="mb-3">
                <Form.Control type="tel" placeholder="Номер телефона" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} className='fw-bold' required />
              </Form.Group>
              <Button variant="dark" type="submit" size="lg" className="mr-2 mt-4 fw-bold border-1 border-white w-100">Заказать консультацию</Button>
            </Form>
          </Col>
          <Col lg={6} className="text-center text-lg-end d-none d-md-block">
            <h2 className="mb-3 fw-light text-secondary">Звоните прямо сейчас!</h2>
            <p className="text-white fw-bold">Получите бесплатную консультацию и просчет стоимости установки.</p>
            <p>
              <FontAwesomeIcon icon={faPhone} color='white' size='2x'/>
              <a href="tel:+380123456789" className="h2 text-white fw-bold text-decoration-none btn-secondary"> +38(093)239-0-777</a>
            </p>
          </Col>
        </Row>
      </Container>
    </Parallax>
  );
}

export default HeaderBlock;
