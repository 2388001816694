import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link as ScrollLink } from 'react-scroll';  // Импортируем ScrollLink для плавной прокрутки
import './styles/navigationBlock.css';

function Navigation() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top">
      <Container fluid className='flex-column navigation__container'>
        <Navbar.Brand href="#"
        style={{ fontFamily: 'monospace', color: '#0000003b', textShadow: '2px 2px #00000012' }}>roletu-odessa.in.ua</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            {/* Замена Nav.Link на ScrollLink для плавной прокрутки и подсветки */}
            <ScrollLink 
              activeClass="active" 
              to="problems" 
              spy={true} 
              smooth={true} 
              duration={500} 
              className="nav-link" 
              offset={-70}>
              Что беспокоит наших клиентов
            </ScrollLink>
            <ScrollLink 
              activeClass="active" 
              to="team" 
              spy={true} 
              smooth={true} 
              duration={500} 
              className="nav-link" 
              offset={-70}>
              О нас
            </ScrollLink>
            <ScrollLink 
              activeClass="active" 
              to="prices" 
              spy={true} 
              smooth={true} 
              duration={500} 
              className="nav-link" 
              offset={-70}>
              Цены
            </ScrollLink>
            <ScrollLink 
              activeClass="active" 
              to="portfolio" 
              spy={true} 
              smooth={true} 
              duration={500} 
              className="nav-link" 
              offset={-70}>
              Портфолио
            </ScrollLink>
            <ScrollLink 
              activeClass="active" 
              to="contact" 
              spy={true} 
              smooth={true} 
              duration={500} 
              className="nav-link" 
              offset={-70}>
              Контакты
            </ScrollLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;