// import logo from './logo.svg';
import './App.css';
import HeaderBlock from './Pages/Landing/HeaderBlock';
import FooterBlock from './Pages/Landing/FooterBlock';
import ProblemsBlock from './Pages/Landing/ProblemBlock';
import TeamBlock from './Pages/Landing/TeamBlock';
import PricesBlock from './Pages/Landing/PricesBlock';
import PortfolioBlock from './Pages/Landing/PortfolioBlock';
import ContactBlock from './Pages/Landing/ContactBlock';
import Navigation from './Pages/Landing/Navigation';
import RollerShuttersInfo from './Pages/Landing/RollerShuttersInfo';
import UTP from './Pages/Landing/UTP';

// import NavScrollExample from './Pages/Landing/Nav';

import 'bootstrap/dist/css/bootstrap.min.css';
import UniqueOfferBlock from './Pages/Landing/UTP';

function App() {
  return (
    <>
    {/* <NavScrollExample /> */}
    <HeaderBlock />
    <ProblemsBlock />
    <RollerShuttersInfo />
    <TeamBlock />
    <UniqueOfferBlock />
    <PricesBlock />
    <PortfolioBlock />
    <ContactBlock />
    <Navigation />
    <FooterBlock />
    </>
  );
}

export default App;
