import React, { useState } from 'react';
import axios from 'axios';
import config from './config'; // Импорт конфигурационного файла

const TelegramForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    text: ''
  });
  const [phoneError, setPhoneError] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validatePhoneNumber(formData.phoneNumber)) {
      setPhoneError('Некорректный номер телефона');
      return;
    }
    const { name, phoneNumber, text } = formData;
    const messageText = `Заказ обратного звонка\n\nИмя: ${name}\nНомер телефона: ${phoneNumber}\n\n${text}`;

    for (const chatId of config.telegramChatIds) {
      try {
        const response = await axios.post(`https://api.telegram.org/bot${config.telegramBotToken}/sendMessage`, {
          chat_id: chatId,
          text: messageText
        });
        console.log(`Response to chat ${chatId}:`, response.data);
      } catch (error) {
        console.error(`Error sending to chat ${chatId}:`, error);
        alert(`Ошибка при отправке сообщения в бот для чата ${chatId}!`);
      }
    }
    alert('Сообщения успешно отправлены!');
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+380\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">Имя</label>
        <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
      </div>
      <div className="mb-3">
        <label htmlFor="phoneNumber" className="form-label">Номер телефона</label>
        <input type="tel" className="form-control" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} required />
        {phoneError && <div className="text-danger">{phoneError}</div>}
        <div className="form-text">Введите номер телефона в формате +380XXXXXXXXX</div>
      </div>
      <div className="mb-3">
        <label htmlFor="text" className="form-label">Дополнительная информация</label>
        <textarea className="form-control" id="text" name="text" value={formData.text} onChange={handleInputChange}></textarea>
      </div>
      <button type="submit" className="btn btn-outline-dark">Отправить сообщение</button>
    </form>
  );
};

export default TelegramForm;
